var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1080", scrollable: "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-playlist-group" }),
                  _vm._v(_vm._s(_vm.$t("Playlist Group Detail")))
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _vm.plItem.length > 0
                    ? _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "dl",
                                  { staticClass: "playlist-detail-info" },
                                  [
                                    _c("dt", [_vm._v(_vm._s(_vm.$t("Title")))]),
                                    _c(
                                      "dd",
                                      {
                                        staticStyle: {
                                          "max-width": "150px",
                                          "text-overflow": "ellipsis",
                                          "white-space": "nowrap",
                                          overflow: "hidden"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.plItem[0].title))]
                                    )
                                  ]
                                )
                              ]),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "dl",
                                  { staticClass: "playlist-detail-info" },
                                  [
                                    _c("dt", [_vm._v(_vm._s(_vm.$t("Model")))]),
                                    _c("dd", [
                                      _vm._v(_vm._s(_vm.plItem[0].device_type))
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "dl",
                                  { staticClass: "playlist-detail-info" },
                                  [
                                    _c("dt", [
                                      _vm._v(_vm._s(_vm.$t("Category")))
                                    ]),
                                    _c(
                                      "dd",
                                      {
                                        staticStyle: {
                                          "max-width": "150px",
                                          "text-overflow": "ellipsis",
                                          "white-space": "nowrap",
                                          overflow: "hidden"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.plItem[0].category))]
                                    )
                                  ]
                                )
                              ]),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "dl",
                                  { staticClass: "playlist-detail-info" },
                                  [
                                    _c("dt", [_vm._v(_vm._s(_vm.$t("Time")))]),
                                    _c("dd", [
                                      _vm._v(_vm._s(_vm.getPlaytime()))
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { staticClass: "mt-4", attrs: { column: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "playlist-group-time",
                              attrs: { cols: "5" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mr-8" },
                                    [
                                      _c("v-slider", {
                                        staticClass: "playslider-vertical",
                                        attrs: { vertical: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: _vm.decrement
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/img/ico-play-up.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "prepend",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: _vm.increment
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/img/ico-play-down.png")
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3494992487
                                        ),
                                        model: {
                                          value: _vm.timeSlider,
                                          callback: function($$v) {
                                            _vm.timeSlider = $$v
                                          },
                                          expression: "timeSlider"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "playlist-group-timeline" },
                                    [
                                      _c(
                                        "perfect-scrollbar",
                                        {
                                          style: {
                                            height: "418px",
                                            "padding-right": "16px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "playlist-timeline"
                                            },
                                            [
                                              _c(
                                                "ul",
                                                _vm._l(24, function(time) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: time.index,
                                                      style:
                                                        time < 24
                                                          ? {
                                                              height:
                                                                _vm.timeLineHeight +
                                                                "px",
                                                              position:
                                                                "relative"
                                                            }
                                                          : {
                                                              height: "initial"
                                                            }
                                                    },
                                                    _vm._l(6, function(minute) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.timeSlider >
                                                                  50 ||
                                                                minute === 1,
                                                              expression:
                                                                "timeSlider > 50 || minute === 1"
                                                            }
                                                          ],
                                                          key: minute.index,
                                                          style: {
                                                            height:
                                                              _vm.timeLineHeight /
                                                                6 +
                                                              "px",
                                                            width: "100%"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getTimeText(
                                                                time,
                                                                minute
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    }),
                                                    0
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "playlist-events" },
                                            [
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  _vm.playlistSet,
                                                  function(item, index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "playlist-event",
                                                        style: {
                                                          top:
                                                            (_vm.timeLineHeight /
                                                              60) *
                                                              item.startTime +
                                                            14 +
                                                            "px",
                                                          height:
                                                            (_vm.timeLineHeight /
                                                              60) *
                                                              (item.endTime -
                                                                item.startTime) +
                                                            "px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            class: item.active
                                                              ? "active"
                                                              : "",
                                                            attrs: { href: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.onClickItem(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "em",
                                                              {
                                                                staticClass:
                                                                  "playlist-event-name"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.text
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.dialog
                            ? _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "margin-left": "3%",
                                    "max-width": "calc(58.3333333333% - 3%)"
                                  },
                                  attrs: { cols: "7" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "playlist-group-preview" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "video-preview",
                                          attrs: { flat: "" }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _vm._v(_vm._s(_vm.$t("PREVIEW")))
                                          ]),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "text-center pb-10"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "px-6" },
                                                [
                                                  _vm.playlistSet.length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "img-preview row"
                                                        },
                                                        _vm._l(
                                                          _vm.playlistSet[0]
                                                            .division.division,
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                style: {
                                                                  width:
                                                                    item + "%"
                                                                }
                                                              },
                                                              [
                                                                _vm.playlistSet[
                                                                  _vm
                                                                    .selectEventIndex
                                                                ].division[
                                                                  index
                                                                ].length > 0
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "v-carousel",
                                                                          {
                                                                            attrs: {
                                                                              tag:
                                                                                "div",
                                                                              cycle:
                                                                                "",
                                                                              interval:
                                                                                "2500",
                                                                              "hide-delimiter-background":
                                                                                "",
                                                                              "hide-delimiters":
                                                                                "",
                                                                              height:
                                                                                _vm
                                                                                  .plItem[0]
                                                                                  .device_type ===
                                                                                "23"
                                                                                  ? 39.5
                                                                                  : 135,
                                                                              "show-arrows": false
                                                                            }
                                                                          },
                                                                          _vm._l(
                                                                            JSON.parse(
                                                                              _vm
                                                                                .playlistSet[
                                                                                _vm
                                                                                  .selectEventIndex
                                                                              ]
                                                                                .division[
                                                                                index
                                                                              ][
                                                                                _vm
                                                                                  .selectMediaIndex
                                                                              ]
                                                                                .still_shot
                                                                            ),
                                                                            function(
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "v-carousel-item",
                                                                                {
                                                                                  key: index,
                                                                                  attrs: {
                                                                                    src:
                                                                                      _vm.srcURL +
                                                                                      item,
                                                                                    "reverse-transition":
                                                                                      "fade-transition",
                                                                                    transition:
                                                                                      "fade-transition"
                                                                                  }
                                                                                }
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "caption text-left pt-6 pb-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Playlist")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "custom-scrollBox"
                                                    },
                                                    [
                                                      _vm.playlistSet.length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row simple-line-table"
                                                            },
                                                            _vm._l(
                                                              _vm.playlistSet[
                                                                _vm
                                                                  .selectEventIndex
                                                              ].division,
                                                              function(
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                    staticClass:
                                                                      "d-flex flex-column",
                                                                    style: {
                                                                      width:
                                                                        _vm
                                                                          .playlistSet[
                                                                          _vm
                                                                            .selectEventIndex
                                                                        ]
                                                                          .division
                                                                          .division[
                                                                          index
                                                                        ] + "%"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    item,
                                                                    function(
                                                                      media,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: i,
                                                                          staticClass:
                                                                            "d-flex .justify-space-around table-line width-full"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-center width-full"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      media.media_name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "NO CONTENTS"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.cancelDetail }
                    },
                    [_vm._v(_vm._s(_vm.$t("Close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }