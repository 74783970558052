var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-playlist-group" }),
        _vm._v(_vm._s(_vm.$t("Playlist Group List")) + " ")
      ]),
      _c(
        "section",
        { staticClass: "searchArea justify-space-between" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "5" } },
            [
              _c("v-combobox", {
                staticClass: "form-select bg-gray",
                staticStyle: { "max-width": "150px" },
                attrs: {
                  label: _vm.$t("Search"),
                  items: _vm.searchByOptions,
                  "item-text": "text",
                  "item-value": "value",
                  type: "button",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              }),
              _c("v-text-field", {
                staticClass: "form-input in-btn ml-3",
                attrs: {
                  placeholder: _vm.$t(_vm.getPlaceholder(_vm.search.text)),
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  clearable: ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchAnchors($event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "" },
                            on: { click: _vm.searchAnchors }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/btn-grey_search.jpg"),
                                alt: ""
                              }
                            })
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.search.value,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "value", $$v)
                  },
                  expression: "search.value"
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "2" } },
            [
              _c("v-autocomplete", {
                staticClass: "form-select bg-gray",
                attrs: {
                  label: _vm.$t("Default Contents"),
                  items: _vm.defaultModel,
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.defaultGroup.selectModel,
                  callback: function($$v) {
                    _vm.$set(_vm.defaultGroup, "selectModel", $$v)
                  },
                  expression: "defaultGroup.selectModel"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                staticClass: "form-input ml-3",
                attrs: {
                  "append-icon": _vm.defaultContents ? "mdi-close" : "",
                  outlined: "",
                  dense: "",
                  disabled: _vm.btnDiasbledDefaultGroup,
                  "hide-details": "",
                  readonly: ""
                },
                on: { "click:append": _vm.clearDefaultConfirm },
                model: {
                  value: _vm.defaultContents,
                  callback: function($$v) {
                    _vm.defaultContents = $$v
                  },
                  expression: "defaultContents"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-9",
          attrs: {
            options: _vm.options,
            headers: _vm.playlistHeaders,
            items: _vm.playlist,
            "hide-default-footer": true,
            "item-key": "idx_playlist_set",
            "show-select": ""
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "item.title",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "a",
                    {
                      staticStyle: {
                        "max-width": "157px",
                        "text-overflow": "ellipsis",
                        "white-space": "nowrap",
                        overflow: "hidden"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showPlaylistDetail(item)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                ]
              }
            },
            {
              key: "item.play_lcd",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: item.play_lcd === "0" ? "#E27070" : "#C8EEBB"
                      }
                    },
                    [_vm._v("mdi-circle")]
                  ),
                  _vm._v(" " + _vm._s(item.play_lcd) + " ")
                ]
              }
            },
            {
              key: "item.category",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-width": "157px",
                        "text-overflow": "ellipsis",
                        "white-space": "nowrap",
                        overflow: "hidden"
                      }
                    },
                    [_vm._v(" " + _vm._s(item.category) + " ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c("playlist-group-detail", {
        attrs: { plItem: _vm.playlistItem, store: _vm.store },
        on: { fireResetPlaylist: _vm.resetPlaylist }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-block mt-4 mb-2" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn mb-2",
                    attrs: { disabled: _vm.addBtnDisabled, text: "" },
                    on: {
                      click: function($event) {
                        return _vm.newPlaylistGroup()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("New")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn ml-3 mb-2",
                    attrs: { disabled: _vm.editBtnDisabled, text: "" },
                    on: {
                      click: function($event) {
                        return _vm.editPlaylistGroup()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Edit")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn ml-3 mb-2",
                    attrs: { disabled: _vm.deleteBtnDisabled, text: "" },
                    on: {
                      click: function($event) {
                        _vm.deleteDialog = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Delete")))]
                )
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn",
                attrs: { disabled: _vm.defaultBtnDisabled, text: "" },
                on: {
                  click: function($event) {
                    _vm.setDefaultGroup = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Set Default Group")))]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-3",
                attrs: { disabled: _vm.exportBtnDisabled, text: "" },
                on: {
                  click: function($event) {
                    return _vm.exportPlaylist()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Export")))]
            )
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "pageInfo" }, [
            _vm._v(_vm._s(_vm.pageInfoText))
          ]),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c(
                "button",
                {
                  staticClass: "pagination-nav pagination-nav-prev",
                  class: _vm.page < 10 ? "pagination-nav-disabled" : null,
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goTenPageBackwards(_vm.page, _vm.paging)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets//img/ico-paging-prev.png"),
                      alt: "이전 10페이지 이동"
                    }
                  })
                ]
              ),
              _c("v-pagination", {
                staticStyle: { display: "block" },
                attrs: {
                  length: _vm.totalPages * 1 || 1,
                  "total-visible": 10,
                  color: "#2f3b4c"
                },
                on: { input: _vm.paging },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              }),
              _c(
                "button",
                {
                  staticClass: "pagination-nav pagination-nav-next",
                  class:
                    _vm.page + 10 > _vm.totalPages
                      ? "pagination-nav-disabled"
                      : null,
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goTenPageForward(
                        _vm.page,
                        _vm.paging,
                        _vm.totalPages
                      )
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets//img/ico-paging-next.png"),
                      alt: "다음 10페이지 이동"
                    }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "goto-Page d-flex align-center" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Go to")))]),
                  _c("v-text-field", {
                    staticClass: "form-input noneline",
                    attrs: {
                      placeholder: "Page",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.goToPage(
                          _vm.goToPageInput,
                          _vm.paging,
                          _vm.totalPages
                        )
                      }
                    },
                    model: {
                      value: _vm.goToPageInput,
                      callback: function($$v) {
                        _vm.goToPageInput = $$v
                      },
                      expression: "goToPageInput"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "1000" },
          on: { "click:outside": _vm.cancelDefaultPlaylistGroup },
          model: {
            value: _vm.setDefaultGroup,
            callback: function($$v) {
              _vm.setDefaultGroup = $$v
            },
            expression: "setDefaultGroup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-playlist-group" }),
                _vm._v(_vm._s(_vm.$t("Default Playlist Group")))
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              items: _vm.defaultModel,
                              label: _vm.$t("Template size"),
                              outlined: "",
                              dense: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.defaultGroup.model,
                              callback: function($$v) {
                                _vm.$set(_vm.defaultGroup, "model", $$v)
                              },
                              expression: "defaultGroup.model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "tabs-type tabs-small",
                              attrs: {
                                "background-color": "transparent",
                                "hide-slider": "",
                                "center-active": "",
                                "active-class": "activeTab"
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#tab-1st" }
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.$t("VIDEO")))])]
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text tab-divider",
                                  attrs: { href: "#tab-2nd" }
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.$t("IMAGE")))])]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "rounded-0 tabs-type-body" },
                            [
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab"
                                  }
                                },
                                [
                                  _vm.tab === "tab-1st"
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            value: "tab-1st",
                                            transition: false,
                                            "reverse-transition": false
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-data-table",
                                                        {
                                                          staticClass:
                                                            "gray-data-table",
                                                          attrs: {
                                                            items:
                                                              _vm.videoData,
                                                            "items-per-page": 1000000,
                                                            "hide-default-footer":
                                                              "",
                                                            height: "301"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "body",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var items =
                                                                    ref.items
                                                                  return [
                                                                    items.length >
                                                                    0
                                                                      ? _c(
                                                                          "tbody",
                                                                          _vm._l(
                                                                            items,
                                                                            function(
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "tr",
                                                                                {
                                                                                  key: index,
                                                                                  class: {
                                                                                    selectedRow:
                                                                                      item.idx_media ===
                                                                                      _vm
                                                                                        .defaultGroup
                                                                                        .idx_media
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.selectItem(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.media_name
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _c(
                                                                          "tbody",
                                                                          [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    attrs: {
                                                                                      colspan:
                                                                                        "4"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-center"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "No data available"
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2041938501
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "no-data" },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "No data available"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tab === "tab-2nd"
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            value: "tab-2nd",
                                            transition: false,
                                            "reverse-transition": false
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-data-table",
                                                        {
                                                          staticClass:
                                                            "gray-data-table",
                                                          attrs: {
                                                            items:
                                                              _vm.imageData,
                                                            "items-per-page": 1000000,
                                                            "hide-default-footer":
                                                              "",
                                                            height: "301"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "body",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var items =
                                                                    ref.items
                                                                  return [
                                                                    items.length >
                                                                    0
                                                                      ? _c(
                                                                          "tbody",
                                                                          _vm._l(
                                                                            items,
                                                                            function(
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "tr",
                                                                                {
                                                                                  key: index,
                                                                                  class: {
                                                                                    selectedRow:
                                                                                      item.idx_media ===
                                                                                      _vm
                                                                                        .defaultGroup
                                                                                        .idx_media
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.selectItem(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.media_name
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _c(
                                                                          "tbody",
                                                                          [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    attrs: {
                                                                                      colspan:
                                                                                        "4"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-center"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "No data available"
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2041938501
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "no-data" },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "No data available"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticStyle: {
                            "margin-left": "40px",
                            "max-width": "calc(50% - 40px)"
                          },
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "video-preview",
                              attrs: { flat: "" }
                            },
                            [
                              _c("v-card-title", [
                                _vm._v(_vm._s(_vm.$t("PREVIEW")))
                              ]),
                              _vm.defaultGroup.idx_media > 0
                                ? _c(
                                    "v-card-text",
                                    { style: { height: "278px" } },
                                    [
                                      _c(
                                        "v-carousel",
                                        {
                                          attrs: {
                                            tag: "div",
                                            cycle: "",
                                            interval: "2500",
                                            "hide-delimiter-background": "",
                                            "hide-delimiters": "",
                                            height: "250",
                                            "show-arrows": false
                                          }
                                        },
                                        _vm._l(_vm.defaultGroup.media, function(
                                          item,
                                          index
                                        ) {
                                          return _c("v-carousel-item", {
                                            key: index,
                                            attrs: {
                                              src: _vm.srcURL + item,
                                              "reverse-transition":
                                                "fade-transition",
                                              transition: "fade-transition"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c("v-card-text", {
                                    staticStyle: { height: "278px" }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.SaveDefaultPlaylistGroup }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.cancelDefaultPlaylistGroup }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "370" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup-success" },
            [
              _c("i", { staticClass: "ico-success" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-caution.png"),
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "msg" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("Delete")))]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Are you sure you want to delete?")))
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center btnArea" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.deletePlaylistGroup }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("No")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }