<template>
    <div>
        <h2 class="page-title-bar">
          <i class="ico ico-playlist-group"></i>{{ $t("Playlist Group List") }}
        </h2>
        <!-- Search bar -->
        <section class="searchArea justify-space-between">
          <v-col cols="5" class="d-flex">
            <v-combobox
              v-model="search"
              :label="$t('Search')"
              :items="searchByOptions"
              item-text="text"
              item-value="value"
              class="form-select bg-gray"
              type="button"
              outlined
              dense
              hide-details
              style="max-width:150px;"
            ></v-combobox>
            <v-text-field
              v-model="search.value"
              @keyup.enter="searchAnchors"
              :placeholder="$t(getPlaceholder(search.text))"
              outlined
              dense
              hide-details
              class="form-input in-btn ml-3"
              clearable
            >
              <template v-slot:append>
                <v-btn @click="searchAnchors" text>
                  <img src="@/assets/img/btn-grey_search.jpg" alt="" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-spacer />
          <v-col cols="2" class="d-flex">
            <v-autocomplete
              v-model="defaultGroup.selectModel"
              :label="$t('Default Contents')"
              :items="defaultModel"
              class="form-select bg-gray"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" class="d-flex">
            <v-text-field
              v-model="defaultContents"
              @click:append="clearDefaultConfirm"
              :append-icon="defaultContents ? 'mdi-close' : ''"
              class="form-input ml-3"
              outlined
              dense
              :disabled="btnDiasbledDefaultGroup"
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </section>
        <!-- Product list -->
        <v-data-table
          v-model="selected"
          :options.sync="options"
          :headers="playlistHeaders"
          :items="playlist"
          :hide-default-footer="true"
          item-key="idx_playlist_set"
          show-select
          class="tbl-type01 mt-9"
        >

          <!-- eslint-disable-next-line -->
          <template v-slot:item.title="{ item }">
            <a style="max-width: 157px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" @click="showPlaylistDetail(item)"> {{ item.title }} </a>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.play_lcd="{ item }">
            <v-icon :color="item.play_lcd === '0' ? '#E27070' : '#C8EEBB'">mdi-circle</v-icon> {{ item.play_lcd }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.category="{ item }">
            <div style="max-width: 157px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"> {{ item.category }} </div>
          </template>

          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
        </v-data-table>

        <playlist-group-detail
          @fireResetPlaylist="resetPlaylist"
          :plItem="playlistItem"
          :store="store"
        />

        <!-- button group -->
      <div class="table-options">
        <div>
          <div class="d-block mt-4 mb-2">
            <v-btn
              :disabled="addBtnDisabled"
              @click="newPlaylistGroup()"
              text
              class="btn mb-2"
              >{{ $t('New') }}</v-btn>
            <v-btn
              :disabled="editBtnDisabled"
              @click="editPlaylistGroup()"
              text
              class="btn ml-3 mb-2"
              >{{ $t('Edit') }}</v-btn>
            <v-btn
              :disabled="deleteBtnDisabled"
              @click="deleteDialog = true"
              text
              class="btn ml-3 mb-2"
              >{{ $t('Delete') }}</v-btn>
          </div>
          <v-btn
            :disabled="defaultBtnDisabled"
            @click="setDefaultGroup = true"
            text
            class="btn"
            >{{ $t('Set Default Group') }}</v-btn>
            <v-btn
              :disabled="exportBtnDisabled"
              @click="exportPlaylist()"
              text
              class="btn ml-3"
              >{{ $t('Export') }}</v-btn>
        </div>
        <!-- paging -->
        <div>
          <div class="pageInfo">{{ pageInfoText }}</div>
          <div class="pagination">
            <!-- 페이지 앞 뒤 이동 -->
            <button
              @click="goTenPageBackwards(page, paging)"
              :class="page < 10 ? 'pagination-nav-disabled' : null"
              class="pagination-nav pagination-nav-prev"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-prev.png"
                alt="이전 10페이지 이동"
              />
            </button>
            <v-pagination
              v-model="page"
              @input="paging"
              :length="totalPages * 1 || 1"
              :total-visible="10"
              color="#2f3b4c"
              style="display: block;"
            ></v-pagination>
            <button
              @click="goTenPageForward(page, paging, totalPages)"
              :class="page + 10 > totalPages ? 'pagination-nav-disabled' : null"
              class="pagination-nav pagination-nav-next"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-next.png"
                alt="다음 10페이지 이동"
              />
            </button>

            <div class="goto-Page d-flex align-center">
              <label>{{$t('Go to')}}</label>
              <v-text-field
                v-model="goToPageInput"
                class="form-input noneline"
                placeholder="Page"
                outlined
                dense
                hide-details
                solo
                @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
              ></v-text-field>

              <!-- <button
                @click="goToPage(goToPageInput, paging, totalPages)"
                type="button"
                class="btnGoToPage"
              >
                <i
                  class="v-icon notranslate mdi mdi-chevron-right theme--light"
                  aria-hidden="true"
                >
                </i>
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <v-dialog v-model="setDefaultGroup" width="1000" @click:outside="cancelDefaultPlaylistGroup">
        <v-card class="popup add_store_popup">
          <h3 class="page-title-bar"><i class="ico ico-playlist-group"></i>{{$t('Default Playlist Group')}}</h3>
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-select
                  v-model="defaultGroup.model"
                  :items="defaultModel"
                  :label="$t('Template size')"
                  class="form-select"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col cols="6">
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  hide-slider
                  center-active
                  active-class="activeTab"
                  class="tabs-type tabs-small">
                  <v-tab href="#tab-1st" class="primary--text">
                    <span>{{ $t("VIDEO") }}</span>
                  </v-tab>
                  <v-tab href="#tab-2nd" class="primary--text tab-divider">
                    <span>{{ $t("IMAGE") }}</span>
                  </v-tab>
                </v-tabs>
                <v-card  class="rounded-0 tabs-type-body">
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      :value="'tab-1st'"
                      :transition="false"
                      :reverse-transition="false"
                      v-if="tab === 'tab-1st'"
                    >
                      <v-card flat>
                        <v-card flat>
                          <v-card-text>
                            <v-data-table
                              :items="videoData"
                              :items-per-page="1000000"
                              hide-default-footer
                              height="301"
                              class="gray-data-table"
                            >
                              <template v-slot:body="{ items }">
                                <tbody v-if="items.length > 0">
                                  <tr v-for="(item, index) in items" :key="index" @click="selectItem(item)" :class="{'selectedRow': item.idx_media === defaultGroup.idx_media}">
                                    <td>{{item.media_name}}</td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="4">
                                      <p class="text-center">
                                        {{ $t('No data available') }}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                              <template slot="no-data">
                                <p>
                                  {{ $t('No data available') }}
                                </p>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      :value="'tab-2nd'"
                      :transition="false"
                      :reverse-transition="false"
                      v-if="tab === 'tab-2nd'"
                    >
                      <v-card flat>
                        <v-card flat>
                          <v-card-text>
                            <v-data-table
                              :items="imageData"
                              :items-per-page="1000000"
                              hide-default-footer
                              height="301"
                              class="gray-data-table"
                            >
                              <template v-slot:body="{ items }">
                                <tbody v-if="items.length > 0">
                                  <tr v-for="(item, index) in items" :key="index" @click="selectItem(item)" :class="{'selectedRow': item.idx_media === defaultGroup.idx_media}">
                                    <td>{{item.media_name}}</td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="4">
                                      <p class="text-center">
                                        {{ $t('No data available') }}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                              <template slot="no-data">
                                <p>
                                  {{ $t('No data available') }}
                                </p>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
              <v-col cols="6" style="margin-left:40px;max-width:calc(50% - 40px)">
                <v-card flat class="video-preview">
                  <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                  <v-card-text v-if="defaultGroup.idx_media > 0" :style="{ height: '278px' }">
                    <v-carousel
                      tag="div"
                      cycle
                      interval="2500"
                      hide-delimiter-background
                      hide-delimiters
                      height="250"
                      :show-arrows="false"
                    >
                      <v-carousel-item
                      v-for="(item, index) in defaultGroup.media"
                      :key="index"
                      :src="srcURL + item"
                      reverse-transition="fade-transition"
                      transition="fade-transition">
                        <!-- <img :src="item" alt="" class="thumbnail_img"> -->
                      </v-carousel-item>
                    </v-carousel>
                  </v-card-text>
                  <v-card-text style="height: 278px;" v-else>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon @click="SaveDefaultPlaylistGroup" class="btn">{{$t('Save')}}</v-btn>
            <v-btn text icon @click="cancelDefaultPlaylistGroup" class="btn">{{$t('Cancel')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" width="370">
      <v-card class="popup-success">
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t("Delete") }}</h3>
          <p>{{ $t("Are you sure you want to delete?") }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="deletePlaylistGroup">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="deleteDialog = false" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <!-- <v-dialog v-model="deleteDialog" width="370">
        <v-card class="popup-success">
          <i class="ico-error"><img src="@/assets/img/ico-unassign.png" alt=""></i>
          <div class="msg">
              <h3>{{ $t('Delete') }}</h3>
              <p>{{ $t('Are you sure you want to delete?') }}</p>
          </div>
          <v-card-actions class="d-flex justify-center btnArea">
              <v-btn text icon class="btn" @click="deletePlaylistGroup">{{$t('Yes')}}</v-btn>
              <v-btn text icon @click="deleteDialog = false" class="btn">{{$t('No')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import commons from '@/plugins/commons'
// import { exportFiles } from '@/lcd/mixin/excel'
// import { paging } from '@/lcd/mixin/lcdpaging'
import PlaylistGroupDetail from '@/lcd/views/Playlist/modal/PlaylistGroupDetail.vue'
import XLSX from 'xlsx'

export default {
  components: {
    PlaylistGroupDetail
  },
  name: 'Playlist',
  data () {
    return {
      deleteDialog: false,
      srcURL: dicamoApi.requests.thumbnail.url,
      btnDisabledPlaylistAddEditDelete: false,
      btnDiasbledDefaultGroup: false,
      btnDisabledPlaylistExport: false,
      showPlaylistDetailPage: false,
      playlistItem: [],
      limit: 10,

      // selectPlaylist: [],
      videoData: [],
      imageData: [],
      playlist: [],
      defaultGroup: {
        selectModel: '23',
        model: '23',
        idx_media: null,
        media: null,
        contents: ''
      },
      exportOption: {
        value: '',
        type: ''
      },
      options: {},
      totalProducts: null,
      selected: [],
      goToPageInput: null,
      tab: null,
      page: 1,
      search: {
        options: 'Category',
        text: this.$t('Category'),
        value: null
      },
      setDefaultGroup: false,
      events: []
    }
  },
  computed: {
    addBtnDisabled () {
      return this.btnDisabledPlaylistAddEditDelete || !this.store.code
    },
    editBtnDisabled () {
      return this.btnDisabledPlaylistAddEditDelete || this.selected.length !== 1
    },
    deleteBtnDisabled () {
      return this.btnDisabledPlaylistAddEditDelete || this.selected.length < 1
    },
    defaultBtnDisabled () {
      return this.btnDiasbledDefaultGroup || !this.store.code
    },
    exportBtnDisabled () {
      let btnDisabled = this.btnDisabledPlaylistExport
      if (!btnDisabled) {
        btnDisabled = this.playlist !== undefined && this.playlist.length < 1
      }
      return btnDisabled
    },
    playlistHeaders () {
      return [
        { text: this.$t('CATEGORY'), value: 'category', width: '15%' },
        { text: this.$t('SIZE'), value: 'device_type', width: '10%' },
        { text: this.$t('TITLE'), value: 'title', width: '20%' },
        { text: this.$t('START TIME'), value: 'start_time', width: '15%' },
        { text: this.$t('END TIME'), value: 'end_time', width: '15%' },
        { text: this.$t('RUNNING TIME'), value: 'running_time', width: '15%' },
        { text: this.$t('PLAYING'), value: 'play_lcd', width: '10%' }
      ]
    },
    searchByOptions () {
      return [
        {
          text: this.$t('Category'),
          options: 'Category'
        },
        {
          text: this.$t('Title'),
          options: 'Title'
        }
      ]
    },
    defaultModel () {
      return [
        {
          text: 'Newton Touch 23"',
          abbr: 'Newton Touch 23"',
          originalText: 'Newton Touch 23"',
          value: '23'
        },
        {
          text: 'Newton Touch 29"',
          abbr: 'Newton Touch 29"',
          originalText: 'Newton Touch 29"',
          value: '29'
        },
        {
          text: 'FHD Box',
          abbr: 'FHD',
          originalText: 'FHD',
          value: 'FHD'
        },
        {
          text: 'Tablet',
          abbr: 'Tablet',
          originalText: 'Tablet',
          value: 'Tablet'
        }
      ]
    },
    totalRecords () {
      let count = 0
      if (!commons.isNull(this.playlist) && this.playlist.length > 0) count = this.playlist[0].total
      return Number(count)
    },
    totalPages () {
      // return Math.round(this.totalRecords / 5)
      return Math.ceil(this.totalRecords / 10)
    },
    pageInfoText () {
      // return ''
      const curPage = (this.page - 1) * 1 * 10 + 1
      const curPageTo = (this.page - 1) * 10 + this.playlist.length * 1
      // if (this.totalPages !== this.page) curPageTo++
      return curPage + this.$t(' to ') + curPageTo + ', ' + this.totalRecords + this.$t('in total   ')
      // return '0 to 0, 0'
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    defaultContents () {
      let contents = ''
      if (this.defaultGroup.contents.length > 0) {
        this.defaultGroup.contents.forEach(item => {
          // console.log(item.device_type)
          // console.log(this.defaultGroup.model)
          if (item.device_type === this.defaultGroup.selectModel) {
            contents = item.media_name
          }
        })
      }
      return contents
    }
  },
  mounted () {
    // Playlist Group New / Edit / Delete Button
    this.$store.dispatch('auth/getDisabledBtn', '24001').then(flag => {
      this.btnDisabledPlaylistAddEditDelete = flag
    })
    // Playlist Group Set Default Group Button
    this.$store.dispatch('auth/getDisabledBtn', '24002').then(flag => {
      this.btnDiasbledDefaultGroup = flag
    })
    // Playlist Group Export
    this.$store.dispatch('auth/getDisabledBtn', '24003').then(flag => {
      this.btnDisabledPlaylistExport = flag
    })
    // Playlist Group : Playlist Group Detail
    this.$store.dispatch('auth/getDisabledBtn', '24004').then(flag => {
      this.showPlaylistDetailPage = flag
    })

    // this.setDiabledBtns()
    EventBus.$emit('enableSelectedStores', true)
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getPlaylistGroup()
      this.getMediaData()
      this.getDefaultGroup()
    }
  },
  methods: {
    clearDefault () {
      if (!this.defaultContents) return

      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'default')
      data.append('device_type', this.defaultGroup.selectModel)
      data.append('idx_media', -1)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.editMediaItem.method,
          dicamoApi.requests.editMediaItem.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          this.getDefaultGroup()
          EventBus.$emit('messageAlert', 'SUCCESS')
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    clearDefaultConfirm () {
      this.$confirm(this.$t('Are you sure you want to delete?', 'OK', 'warning')).then(() => {
        this.clearDefault()
      })
    },
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    getDefaultGroup () {
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'default')
      // form.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getDefaultGroup.method,
          dicamoApi.requests.getDefaultGroup.url,
          form
        )
        .then(res => {
          if (res.data.result) {
            this.defaultGroup.contents = res.data.data
            // console.log(res)
            // console.log('Get Default Group')
          }
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    exportTable (arr) {
      const list = []
      arr.forEach(item => {
        list.push({
          category: item.category,
          size: item.device_type,
          title: item.title,
          start_time: item.start_time,
          end_time: item.end_time,
          running_time: item.running_time
        })
      })
      return list
    },
    exportPlaylist () {
      this.getExportPlaylist()
    },
    showPlaylistDetail (item) {
      if (this.showPlaylistDetailPage) return
      EventBus.$emit('showPlaylistDetail')

      this.getPlaylistItem(item.idx_playlist_set)
    },
    editPlaylistGroup () {
      const index = Number(this.selected[0].idx_playlist_set)
      this.$router.push({ name: 'Dicamo Edit Playlist Group', params: { index: index, is_play: this.selected[0].play_lcd > 0 } })
    },
    deletePlaylistGroup () {
      const form = new FormData()
      this.deleteDialog = false

      const idxs = []
      this.selected.forEach(item => {
        idxs.push(item.idx_playlist_set)
      })

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('idx_playlist_set', JSON.stringify(idxs))
      form.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.deletePlaylist.method,
          dicamoApi.requests.deletePlaylist.url,
          form
        )
        .then(res => {
          if (res.data.result) {
            EventBus.$emit('messageAlert', 'SUCCESS')
            this.resetPlaylist()
          } else {
            EventBus.$emit('messageAlert', res.data.message)
            this.resetPlaylist()
          }
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    // exportPlaylist () {
    //   // if (this.playlist.length < 1) {
    //   //   EventBus.$emit('messageAlert', this.$t('No data to export.'))
    //   //   return
    //   // }
    //   // const requestConfig = {
    //   //   url: dicamoApi.requests.getPlaylist.url,
    //   //   method: dicamoApi.requests.getPlaylist.method,
    //   //   company: this.$store.state.auth.user.company,
    //   //   store: this.$store.state.dataStore.selectedStore.code
    //   // }
    //   // exportFiles(requestConfig, this.$refs.link, 'Playlist.xlsx')
    // },
    getPlaylistData (items) {
      this.playlist = []
      items.forEach(item => {
        let playlistSet = ''
        let data = []
        if (item.playlist_set === '') {
          data = {
            idx_playlist_set: item.idx_playlist_set,
            category: item.category,
            device_type: item.device_type,
            title: item.title,
            start_time: 0,
            end_time: 0,
            running_time: 0,
            play_lcd: item.play_lcd,
            total: item.total
          }
        } else {
          playlistSet = JSON.parse(item.playlist_set)
          data = {
            idx_playlist_set: item.idx_playlist_set,
            category: item.category,
            device_type: item.device_type,
            title: item.title,
            start_time: this.getTime(playlistSet.length > 0 ? playlistSet[0].startTime : ''),
            end_time: this.getTime(playlistSet.length > 0 ? this.getEndTime(playlistSet) : ''),
            running_time: this.getTime(playlistSet.length > 0 ? this.getEndTime(playlistSet) - playlistSet[0].startTime : ''),
            play_lcd: item.play_lcd,
            total: item.total
          }
        }
        this.playlist.push(data)
      })
    },
    getEndTime (array) {
      const times = []
      array.forEach(item => {
        times.push(item.endTime)
      })
      return Math.max(...times)
    },
    getMediaData () {
      // if (commons.isNull(params.store)) return
      // if (this.productionType === dicamoApi.productionTypes.CLOUD) {
      //   params.company = this.$store.state.auth.user.company
      // }
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'list')
      // form.append('searchType', this.search.value ? this.search.by.toLowerCase() : '')
      // form.append('searchWord', this.search.value)
      form.append('limit', 1000000)
      form.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getMedia.method,
          dicamoApi.requests.getMedia.url,
          form
        )
        .then(res => {
          const video = []
          const image = []
          res.data.data.forEach(item => {
            if (item.media_type === 'video') video.push(item)
            else image.push(item)
            this.videoData = video
            this.imageData = image
          })
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    selectItem (item) {
      if (this.defaultGroup.idx_media === item.idx_media) this.defaultGroup.idx_media = null
      else {
        this.defaultGroup.idx_media = item.idx_media
        this.getMediaItem(item.idx_media)
      }
    },
    getMediaItem (index) {
      const form = new FormData()

      form.append('company', this.$store.state.auth.user.company)
      form.append('store', this.$store.state.dataStore.selectedStore.code)
      form.append('type', 'item')
      form.append('idx_media', index)
      form.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getMedia.method,
          dicamoApi.requests.getMedia.url,
          form
        )
        .then(res => {
          this.defaultGroup.media = JSON.parse(res.data.data.still_shot)
        })
        .catch(error => {
          this.defaultMe = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any medias. error: ${error}`)
        })
    },
    // onClickItem (index) {
    //   this.selectPlaylist.playlist_set.forEach(item => {
    //     item.active = false
    //   })
    //   this.selectIndex = index
    //   this.selectPlaylist.playlist_set[index].active = true
    // },
    paging (page) {
      this.page = Number(page)
      this.getPlaylistGroup()
    },
    SaveDefaultPlaylistGroup () {
      if (this.defaultGroup.idx_media === null || this.defaultGroup.idx_media === undefined) {
        EventBus.$emit('messageAlert', 'No selected media.')
        return
      }
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'default')
      data.append('device_type', this.defaultGroup.model)
      data.append('idx_media', this.defaultGroup.idx_media)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.editMediaItem.method,
          dicamoApi.requests.editMediaItem.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          this.getDefaultGroup()
          this.cancelDefaultPlaylistGroup()
          EventBus.$emit('messageAlert', 'SUCCESS')
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    // Default Playlist Group Dialog 닫혔을 때 Scroll 및 데이터 초기화
    cancelDefaultPlaylistGroup () {
      window.document.getElementsByClassName('v-data-table__wrapper')[1].scrollTo(0, 0)
      this.tab = 'tab-1st'
      this.defaultGroup.idx_media = null
      this.defaultGroup.media = null
      this.defaultGroup.model = '23'
      this.setDefaultGroup = false
      this.getDefaultGroup()
    },
    searchAnchors () {
      this.exportOption.value = this.search.value
      this.exportOption.type = this.search.options.toLowerCase()
      this.getPlaylistGroup()
    },
    getExportPlaylist () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'list')
      data.append('page', '1')
      data.append('limit', dicamoApi.EXPORT_SIZE)
      data.append('searchWord', this.exportOption.value)
      data.append('searchType', this.exportOption.type)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getPlaylist.method,
          dicamoApi.requests.getPlaylist.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          const exportItem = this.exportDataFormat(res.data.data)
          const json = this.exportTable(exportItem)

          const excel = XLSX.utils.json_to_sheet(json)

          var wb = XLSX.utils.book_new()

          XLSX.utils.book_append_sheet(wb, excel, 'playlist')

          XLSX.writeFile(wb, 'Playlist.xlsx')
        })
        .catch(() => {

        })
    },
    exportDataFormat (items) {
      const playlist = []
      items.forEach(item => {
        let playlistSet = ''
        let data = []
        if (item.playlist_set === '') {
          data = {
            category: item.category,
            device_type: item.device_type,
            title: item.title,
            start_time: 0,
            end_time: 0,
            running_time: 0
          }
        } else {
          playlistSet = JSON.parse(item.playlist_set)
          data = {
            category: item.category,
            device_type: item.device_type,
            title: item.title,
            start_time: this.getTime(playlistSet.length > 0 ? playlistSet[0].startTime : ''),
            end_time: this.getTime(playlistSet.length > 0 ? playlistSet[playlistSet.length - 1].endTime : ''),
            running_time: this.getTime(playlistSet.length > 0 ? playlistSet[playlistSet.length - 1].endTime - playlistSet[0].startTime : '')
          }
        }
        playlist.push(data)
      })
      return playlist
    },
    getPlaylistGroup () {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'list')
      data.append('page', this.page)
      data.append('limit', this.limit)
      data.append('searchWord', this.search.value ? this.search.value : '')
      data.append('searchType', this.search.value ? this.search.options.toLowerCase() : '')

      if (!commons.isNull(this.options.sortBy))data.append('order', this.options.sortBy)
      if (!commons.isNull(this.options.sortDesc))data.append('order_dir', this.options.sortDesc ? 'desc' : 'asc')
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getPlaylist.method,
          dicamoApi.requests.getPlaylist.url,
          data
        )
        .then(res => {
          // this.playlist = res.data.data
          this.getPlaylistData(res.data.data)
        })
        .catch(error => {
          // this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    resetPlaylist () {
      this.getPlaylistGroup()
      this.playlistItem = []
      this.selected = []
    },
    getPlaylistItem (idxPlaylist) {
      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'item')
      data.append('idx_playlist_set', idxPlaylist)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getPlaylist.method,
          dicamoApi.requests.getPlaylist.url,
          data
        )
        .then(res => {
          this.playlistItem.push(res.data.data)
          // this.getPlaylistData(res.data.data)
        })
        .catch(error => {
          this.mediaDataList = []
          // this.totalMedia = this.mediaDataList[0].length
          console.debug(`Could not find any playlist. error: ${error}`)
        })
    },
    newPlaylistGroup () {
      this.$router.push('/Lcd/Playlist/newPlaylistGroup')
    },
    // showPlaylistDetail (item) {
    //   if (!this.showPlaylistDetailPage) {
    //     this.dialog = true
    //     this.selectPlaylist = item
    //     if (this.selectPlaylist.playlist_set.length > 0) this.selectPlaylist.playlist_set[0].active = true
    //     console.log(item)
    //   } else {
    //     console.log('권한없음')
    //   }
    // },
    getPlaceholder (key) {
      const str = String(key).toLowerCase()
      return `Input ${str}`
    },
    getTime (minute) {
      const cal = minute / 60

      const time = ((Math.floor(cal) + '').padStart(2, '0') + ':' + (minute % 60 + '').padStart(2, '0'))
      return time
    }
  },
  watch: {
    options: {
      handler () {
        this.page = 1
        this.getPlaylistGroup()
      },
      deep: true
    },
    store: {
      handler () {
        this.page = 1
        this.getPlaylistGroup()
        this.getMediaData(1)
        this.getDefaultGroup()
      }
    },
    tab: function (val, oldVal) {
      if (val !== oldVal) {
        // this.getMediaData(1)
        this.defaultGroup.idx_media = null
      }
    }
  }
}
</script>

<style scoped>

::v-deep .v-list-item--link {
  background: #fff !important;
}

::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
::v-deep .v-select__slot > input {
  text-align: left;
}
::v-deep .v-carousel .v-image__image {
  /* object-fit: fill; */
  background-size: contain !important;
}
::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
.selectedRow {
    background-color: #031e37 !important;
    color: white !important;
}
.searchArea .col {padding:0 !important}
.pageInfo  {
  position:relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom:8px;
  font-size: var(--font18);
  color:#797979;
  }

.v-list {padding:0;background: transparent;}
.v-list-item {border-bottom:1px solid #7a7a7a}
.v-list:hover {background-color: transparent;}
.v-list-item-group .v-list-item--active {background-color: #031e37;color:#fff;}

.video-preview .v-card__text {padding: 40px 0 62px}

/* ::v-deep .v-select {  */
::v-deep .v-select__slot > input {
  color: white !important;
}
::v-deep .popup-success .ico-success{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px !important;
    left: 50%;
    width: 58px;
    height: 58px;
    background: #001E38;
    border: 2px solid #fff;
    border-radius: 50% !important;
    transform: translate(-50%, -50%);
}
</style>
