<template>
  <v-dialog v-model="dialog" width="1080" scrollable persistent>
    <v-card v-if="dialog" class="popup add_store_popup">
      <v-card-title><h3 class="page-title-bar"><i class="ico ico-playlist-group"></i>{{$t('Playlist Group Detail')}}</h3></v-card-title>
      <v-card-text class="pa-0">
        <v-layout column v-if="plItem.length > 0">
          <v-row no-gutters>
            <v-col cols="3">
              <dl class="playlist-detail-info">
                <dt>{{$t('Title')}}</dt>
                <dd style="max-width: 150px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ plItem[0].title }}</dd>
              </dl>
            </v-col>
            <v-col cols="3">
              <dl class="playlist-detail-info">
                <dt>{{$t('Model')}}</dt>
                <dd>{{ plItem[0].device_type }}</dd>
              </dl>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3">
              <dl class="playlist-detail-info">
                <dt>{{$t('Category')}}</dt>
                <dd style="max-width: 150px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ plItem[0].category }}</dd>
              </dl>
            </v-col>
            <v-col cols="3">
              <dl class="playlist-detail-info">
                <dt>{{$t('Time')}}</dt>
                <dd>{{ getPlaytime() }}</dd>
              </dl>
            </v-col>
          </v-row>
        </v-layout>
        <v-layout column class="mt-4">
          <v-row no-gutters>
            <v-col cols="5" class="playlist-group-time">
              <div class="d-flex align-center">
                <div class="mr-8">
                  <v-slider v-model="timeSlider" vertical class="playslider-vertical">
                    <template v-slot:append>
                      <v-btn icon small @click="decrement"><img src="@/assets/img/ico-play-up.png"></v-btn>
                    </template>
                    <template v-slot:prepend>
                      <v-btn icon @click="increment"><img src="@/assets/img/ico-play-down.png"></v-btn>
                    </template>
                  </v-slider>
                </div>
                <div class="playlist-group-timeline">
                  <perfect-scrollbar :style="{ height : '418px' , 'padding-right': '16px' }">
                    <div class="playlist-timeline">
                      <ul>
                        <li v-for="time in 24" :key="time.index" :style="time < 24 ? { height: timeLineHeight + 'px', position: 'relative' } : { height: 'initial' }">
                          <div v-show="timeSlider > 50 || minute === 1" v-for="minute in 6" :key="minute.index" :style="{ height: timeLineHeight / 6 + 'px', width: '100%' }">{{ getTimeText(time, minute) }}</div>
                        </li>
                      </ul>
                    </div>
                    <div class="playlist-events">
                      <ul>
                        <li v-for="(item, index) in playlistSet" :key="index" class="playlist-event" :style="{ top: (timeLineHeight / 60 ) * item.startTime + 14 + 'px', height: (timeLineHeight / 60) * (item.endTime - item.startTime) + 'px' }">
                        <a href="" @click.prevent="onClickItem(index)" :class="item.active ? 'active' : ''">
                          <em class="playlist-event-name"> {{ item.text }} </em>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </v-col>
            <v-col v-if="dialog" cols="7" style="margin-left: 3%;max-width: calc(58.3333333333% - 3%);">
              <div class="playlist-group-preview">
                <v-card flat class="video-preview">
                  <v-card-title>{{$t('PREVIEW')}}</v-card-title>
                  <v-card-text class="text-center pb-10">
                    <div class="px-6">
                      <div class="img-preview row" v-if="playlistSet.length > 0">
                        <div v-for="(item, index) in playlistSet[0].division.division" :key="index" :style="{ width: item + '%' }">
                          <div v-if="playlistSet[selectEventIndex].division[index].length > 0">
                            <v-carousel
                              tag="div"
                              cycle
                              interval="2500"
                              hide-delimiter-background
                              hide-delimiters
                              :height="plItem[0].device_type === '23' ? 39.5 : 135"
                              :show-arrows="false"
                            >
                              <v-carousel-item
                              v-for="(item, index) in JSON.parse(playlistSet[selectEventIndex].division[index][selectMediaIndex].still_shot)"
                              :key="index"
                              :src="srcURL + item"
                              reverse-transition="fade-transition"
                              transition="fade-transition">
                                <!-- <img :src="item" alt="" class="thumbnail_img"> -->
                              </v-carousel-item>
                            </v-carousel>
                          </div>
                        </div>
                      </div>
                      <h6 class="caption text-left pt-6 pb-2">{{$t('Playlist')}}</h6>
                      <!-- <perfect-scrollbar ref="sps" :style="{ height : '187px' }"> -->
                      <div class="custom-scrollBox">
                        <!-- <div class="simple-line-table">
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody v-if="playlistSet.length > 0">
                                <tr v-for="(item, index) in playlistSet[selectEventIndex].division" :key="index">
                                  <td v-if="item.length > 0"> {{ item }} </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div> -->
                        <div class="d-flex flex-row simple-line-table" v-if="playlistSet.length > 0">
                          <div class="d-flex flex-column" v-for="(item, index) in playlistSet[selectEventIndex].division" :key="index" :style="{ width: playlistSet[selectEventIndex].division.division[index] + '%' }">
                            <div class="d-flex .justify-space-around table-line width-full" v-for="(media, i) in item" :key="i">
                              <span class="text-center width-full">
                                <p>{{ media.media_name }}</p>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          {{$t('NO CONTENTS')}}
                        </div>
                      </div>
                      <!-- </perfect-scrollbar> -->
                      </div>
                  </v-card-text>
              </v-card>
              </div>
            </v-col>
          </v-row>
        </v-layout>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="cancelDetail" class="btn">{{$t('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import EventBus from '@/plugins/eventBus'
import dicamoApi from '@/lcd/plugins/dicamoApi'

export default {
  props: {
    plItem: Array,
    store: Object
  },
  components: {
    PerfectScrollbar
  },
  mounted () {
    EventBus.$on('showPlaylistDetail', () => {
      this.dialog = true
    })
  },
  data () {
    return {
      srcURL: dicamoApi.requests.thumbnail.url,
      dialog: false,
      selectEventIndex: 0,
      selectMediaIndex: 0,
      timeSlider: 40
    }
  },
  computed: {
    timeLineHeight () {
      return 30 + (this.timeSlider * 2.4)
    },
    playlistSet () {
      const list = this.plItem.length > 0 ? JSON.parse(this.plItem[0].playlist_set) : []

      if (list.length > 0) {
        list.forEach(item => {
          item.active = ''
        })

        list[this.selectEventIndex].active = 'active'
      }
      // if (list.length > 0) list[this.selectEventIndex].active = 'active'

      return list
    }
  },
  methods: {
    getPlaytime () {
      return this.playlistSet.length === 0 ? '' : this.getTime(this.playlistSet[this.selectEventIndex].startTime) + '~' + this.getTime(this.playlistSet[this.selectEventIndex].endTime)
    },
    cancelDetail () {
      this.selectEventIndex = 0
      this.selectMediaIndex = 0
      this.dialog = false
      this.formData = []
      this.$emit('fireResetPlaylist')
    },
    decrement () {
      this.timeSlider += 10
    },
    increment () {
      this.timeSlider -= 10
    },
    getTimeText (hour, minute) {
      const time = ((hour - 1) + '').padStart(2, '0') + ':' + ((minute - 1) + '').padEnd(2, '0')
      return time
    },
    onClickItem (index) {
      this.selectEventIndex = index
    },
    getTime (minute) {
      const cal = minute / 60
      const time = ((Math.floor(cal) + '').padStart(2, '0') + ':' + (minute % 60 + '').padStart(2, '0'))
      return time
    }
  }
}
</script>

<style scoped>
  .table-line {
    border-bottom: 1px solid #959595 !important;
    border-left: 1px solid #959595;
  }
  .v-window__container {
    width: 100%;
  }
  .selectedRow {
      background-color: #d4d4d4;
      font-weight: bold;
  }
  .searchArea .col {padding:0 12px !important}
  .pageInfo  {position:relative;display: flex;align-items: center;margin-bottom:8px}

  .v-list {padding:0;background: transparent;}
  .v-list-item {border-bottom:1px solid #7a7a7a}
  .v-list:hover {background-color: transparent;}
  .v-list-item-group .v-list-item--active {background-color: #031e37;color:#fff;}

  .video-preview .v-card__text {padding: 40px 0 62px}
  ::v-deep .v-image__image {
    background-size: 100% 100%;
  }
  /* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
